<template>
    <div class="verify">
        <div class="group">
            <span class="title">
                分类:
            </span>
            <el-radio-group v-model="verify.type_id">
                <el-radio :key="item.value" v-for="item in  $store.state.dict.answer_type" :label="item.value">
                    {{ item.label }}
                </el-radio>
            </el-radio-group>
        </div>
        <div class="group">
            <span class="title">
                审核:
            </span>
            <el-radio-group v-model="verify.review_status">
                <el-radio :label="200">通过</el-radio>
                <el-radio :label="0">不通过</el-radio>
            </el-radio-group>
        </div>
        <el-button type="primary" @click="saveData" size="medium">确认</el-button>
    </div>
</template>
<script>

export default {
    props: ['answer'],
    data() {
        return {
            baseUrl: '/bbs/answer/verify',
            verify: {
                type_id: '',
                answer_id: '',
                review_status: '',
            },
        }
    },
    watch: {
        //在父组件问题的内容加载完了，那么会走这里，将值处理好
        answer: function () {
            this.verify._type = this.$route.query.type;
            switch (this.$route.query.type) {
                case 'answer':
                    this.verify.answer_id = this.answer.answer_id;
                    this.verify.type_id = this.answer.type_id;
                    this.verify.review_status = this.answer.review_status;
                    break;
                case 'reply':
                    this.verify.answer_id = this.answer.answer_id;
                    this.verify.type_id = this.answer.type_id;
                    this.verify.review_status = this.answer.review_status;
                    break;
            }
        },
    },
    mounted() {
        // console.log(this.$route);
    },
    methods: {
        saveData: function () {
            if (this.verify.review_status == '1' || !this.verify.type_id) {
                return;
            }
            this.$bbs.put(this.baseUrl, this.verify, {need: {error: true}}).then(data => {
                this.$emit('verify', {data: data.data});
                this.$store.commit('handleNotice',{type:'answer',id:this.verify.answer_id});
                this.$message.success('审核成功');
            });
        }
    }
}
</script>
<style scoped lang="less">
.verify {
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 15px;
    left: 0;
    border: 1px solid #ccc;
    right: 0;
    text-align: center;
    padding: 40px;
    height: 40px;
    box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1), 0 7px 18px 0 rgba(21, 111, 170, 0.21);
    background-color: #f0f9eb;

    .group {
        margin-right: 50px;
        padding: 4px;
        //border: 1px dashed rgba(185, 188, 190, 0.5);
        .title {
            font-weight: bold;
            padding-right: 10px;
        }
    }
}
</style>
