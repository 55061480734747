<template>
    <div class="question-wrap">
        <div class="question-title">
            <span class="q-status-icon">
                <i :class="answer.status===100?'el-icon-success':'el-icon-question'"></i>
            </span>
            <h1>{{ answer.title }}</h1>
            <hr>
        </div>
        <div class="question-content bbs-second-color">
            <div ref="content">
                <div v-html="answer.content||''"></div>
            </div>
        </div>
        <div class="question-footer bbs-third-color">
            <div class="tag">
                <!--                <i class="el-icon-collection-tag"></i>-->
                {{ formatType(answer.type_id) }}
            </div>
            <div class="username">
                <span v-if="answer.review_status===1" class="verify">
                    <el-tag size="mini" type="warning">审核中</el-tag>
                </span>
                <span v-if="answer.review_status===0" class="verify">
                    <el-tag size="mini" type="warning">审核不通过</el-tag>
                </span>
                <i class="el-icon-user">

                </i>
                {{ answer.username && answer.username.nickname || 'bigemap用户' }}
            </div>
            <div class="time">
                <i class="el-icon-time"></i>
                <span v-if="answer.update_time&&answer.update_time>answer.create_time">
                    编辑于: <format-time readable="1" :time="answer.update_time"
                                         format="Y-m-d H:i:s"></format-time>
                </span>
                <span v-else>
                    创建于: <format-time readable="1" :time="answer.create_time" format="Y-m-d H:i:s"></format-time>
                </span>
            </div>
        </div>
        <div v-if="answer.attach_list&&answer.attach_list.length>0" class="attach-list">
            <p class="pt-2 pb-2">
                相关附件
            </p>
            <div>
                <el-link @click="downloadAttach(item)" type="primary" class="attach-item" v-for="item in answer.attach_list"
                         :key="item.url||item.src">
                    {{ item.title }}
                </el-link>
            </div>
        </div>
        <div class="add-answer">
            <div class="btn-area">
                <el-button v-if="answer.review_status===200" :loading="$store.state.loading" @click="addReply"
                           size="small" type="primary"
                           icon="el-icon-plus">添加回答
                </el-button>
                <slot name="editQuestion">
                </slot>
            </div>
            <div class="ju-bao">
                <span class="font-12 bbs-third-color">
                <i class="el-icon-warning-outline"></i>举报
                </span>
            </div>
        </div>
        <div class="bbs-third-color font-12">
            提示:请规范的作答，可使您的问题被置顶，并设置为最优回答
        </div>
    </div>
</template>

<script>
import FormatTime from "@/components/common/format-time";
import {reStoreKeyValue} from "@/utils/tools";
import highlightElement from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.min.css';
import 'highlightjs-copy/dist/highlightjs-copy.min.css'
import CopyButtonPlugin from 'highlightjs-copy';

export default {
    props: {
        answer: {
            type: Object,
        }
    },
    components: {
        FormatTime
    },
    name: "question-content",
    data() {
        return {}
    },
    mounted() {
    },
    watch: {
        'answer.answer_id': function () {
            setTimeout(() => {
                highlightElement.addPlugin(new CopyButtonPlugin({
                    lang: "zh"
                }));
                const blocks = this.$refs.content.querySelectorAll('pre code')
                blocks.forEach((block) => {
                    highlightElement.highlightElement(block)
                });
            }, 100);
        }
    },
    methods: {
        addReply() {
            if (!this.$tools.checkLogin()) return;
            this.$emit('answer', {});
        },
        formatType(type_id) {
            return reStoreKeyValue('answer_type', type_id);
        },
        downloadAttach(item){
            let a=document.createElement('a');
            a.download=item.title||'未命名.zip';
            let href=item.url||item.src;
            if (href.substring(0,8)!=='https://'){
                href='https://'+href.substring(7);
            }
            a.href=href;
            a.target='_blank';
            a.click();
        }
    }
}
</script>

<style scoped lang="less">
.question-wrap {
    padding: 20px;
    border-radius: 5px;
    background-color: #ffffff;

    .add-answer {
        display: flex;
        padding: 10px 0 20px 0;
        align-items: center;

        .btn-area {
            flex: 1;
        }

        .ju-bao {
            width: 50px;
            text-align: right;
            cursor: pointer;

            i {
                margin-right: 4px;
            }

            &:hover {
                .bbs-third-color {
                    color: #f1d444;
                }
            }
        }
    }
}

.question-title {
    padding: 0 0 20px 0;
    position: relative;

    h1 {
        text-indent: 30px;
        line-height: 30px;
        font-size: 20px;
        color: #434D6D;
    }

    .q-status-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        top: 4px;
        color: #51a4fe;
        font-size: 20px;
        position: absolute;
    }

}

.question-content {
    //text-indent: 30px;
    line-height: 30px;
}

.attach-list {
    .attach-item {
        padding-right: 15px;
    }
}

.question-footer {
    display: flex;
    padding: 15px 0;
    vertical-align: center;

    > div {
        i {
            margin-right: 5px;
        }

        padding-right: 20px;
    }

    .tag {
        background-color: #E0EFFB;
        padding: 2px 15px;
        color: #1C8CE5;
        border: 1px solid #1C8CE5;
        border-radius: 10px;
        display: inline-block;
        margin-right: 20px;
    }

    .username {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #51a4fe;

        .verify {
            padding-right: 15px;
            cursor: default;
        }
    }

    .time {
        display: flex;
        align-items: center;
    }
}
</style>
