<template>
    <div class="reply-comment">
        <div class="comment-user">
            <div class="user">
                <img :src="comment.from_username&&comment.from_username.face||'/static/bbs/images/answer/face.png'" alt="">
            </div>
            <div class="username bbs-second-color">
                {{ comment.from_username.nickname }}
                <span class="creator-nick" v-if="comment.from_username.username===$store.state.answer.username.username">(提问者)</span>
                <span class="to-username" v-if="comment.to_username&&comment.to_username.nickname">
                    回复 {{comment.to_username.nickname}}
                    <span class="creator-nick" v-if="comment.to_username&&comment.to_username.username===$store.state.answer.username.username">(提问者)</span>
                </span>
            </div>
        </div>
        <div class="comment-body" :id="'comment_id_'+comment.comment_id">
            {{ comment.content }}
        </div>
        <div class="verify-status" v-if="comment.review_status!=200">
            <span v-if="comment.review_status===1">审核中</span>
            <span v-if="comment.review_status===0">不通过</span>
        </div>
        <div v-if="$store.state.userData&&$store.state.userData.group_id==1&&verify.verify&&comment.review_status!=200" class="verify">
            <span class="title">审核:</span>
            <el-radio-group v-model="verify.review_status">
                <el-radio :label="200">通过</el-radio>
                <el-radio :label="0">不通过</el-radio>
            </el-radio-group>
            <span class="verify-btn">
                    <el-button size="medium"  type="primary" @click="verifyItem">确认</el-button>
                </span>
        </div>
        <div class="comment-add">
            <span v-if="comment.review_status===200" @click="addReply" class="comment-btn">
                回复
            </span>
            <span v-else class="comment-btn"></span>
            <span class="time bbs-third-color">
                <format-time readable="1" :time="comment.create_time" format="Y-m-d H:i:s"></format-time>
            </span>
        </div>
        <div v-show="addData.show" class="reply-textarea">
            <el-input
                resize="none"
                type="textarea"
                :rows="3"
                :placeholder="addData.data.placeholder"
                v-model="addData.data.content">
            </el-input>
            <div class="btn-comment-list">
                <el-button size="mini" @click="addData.show=false" plain>取消</el-button>
                <el-button size="mini" @click="saveReply" plain type="primary">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import FormatTime from "@/components/common/format-time";

export default {
    name: "reply-comment",
    props: {
        //这个index 主要是方便使用返回上级来处理添加事件
        index: {},
        comment: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    components: {
        FormatTime
    },
    data() {
        return {
            baseUrl:'/bbs/comment/index',
            baseVerifyUrl:'/bbs/comment/verify',
            verify:{
                verify:!!this.$route.query.verify,
                review_status:this.comment.review_status,
            },
            addData: {
                show: false,
                data: {
                    to_comment_id:'',
                    placeholder: '',
                    content: '',
                }
            }
        }
    },
    methods: {
        addReply() {
            this.addData.show = true;
            this.addData.data.content='';
            this.addData.data.placeholder = '回复' + (this.comment.from_username.nickname || this.comment.from_username.username);
        },
        verifyItem(){
            if (this.verify.review_status=='1') return;
            this.$bbs.put(this.baseVerifyUrl,{
                comment_id:this.comment.comment_id,
                review_status:this.verify.review_status,
            },{need:{error:true}}).then((data)=>{
                this.$message.success('审核成功!');
                this.$store.commit('handleNotice',{type:'comment',id:this.comment.comment_id});
                this.comment.review_status=data.data.review_status;
            });
        },
        saveReply() {
            if (!this.$tools.checkLogin()){
                return;
            }
            if (this.addData.data.content === '') {
                return;
            }
            this.$bbs.post(this.baseUrl, {
                reply_id:this.comment.reply_id,
                content:this.addData.data.content,
                to_comment_id:this.comment.comment_id,
                to_username:this.comment.from_username.username
            }).then(data => {
                this.addData.show=false;
                this.$emit('addComment', {data: data.data, index: this.index,type:'for_comment'});
            });
        }
    },

}
</script>

<style lang="less" scoped>
.reply-comment {
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.34);

    .reply-textarea {
        margin: 10px 0;

        .btn-comment-list {
            margin: 10px;
            text-align: right;
        }
    }
    .verify-status{
        color: #e6a23c;
        padding: 0 10px 10px 0;
    }
    .verify{
        color: #333;
        margin-bottom: 10px;
        padding: 25px 10px;
        background: #f0f9eb;
        .title{
            padding-right: 15px;
        }
        .verify-btn{
            display: inline-block;
            padding-left: 40px;
        }
    }
}

.comment-user {
    display: flex;
    //height: 50px;
    align-items: center;
    font-size: 12px;

    .user {
        margin-right: 10px;

        img {
            display: inline-block;
            border-radius: 100%;
            height: 40px;
            padding: 2px;
            border: 1px solid #666;
        }
    }
}

.comment-body {
    padding: 15px 0;
}

.comment-add {
    display: flex;
    > .comment-btn {
        flex: 1;
        color: #617288;
        cursor: pointer;

        &:hover {
            color: #51a4fe;
        }
    }

    > .time {
        font-size: 12px;
        text-align: right;
        width: 200px;
    }
}
</style>
