<template>
    <div>
        <div class="title">您可能还会对这些问题感兴趣</div>
        <el-row :gutter="15">
            <el-col v-for="(item,index) in question" :key="item.answer_id" :span="12">
                <el-link target="_blank" :href="'/wenda/detail/'+item.answer_id+'.html'" type="primary">
                    <span class="a-title">
                        <span class="index">{{index+1}}:</span>
                        {{ item.title }}</span>
                </el-link>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    computed: {
        question: function () {
            return this.$store.state.connect.map(v => {
                return v;
            });
        }
    },
    methods: {
        // jump(item) {
        //     this.$router.push({
        //         path: '/wenda/detail/' + item.answer_id + '.html'
        //     });
        // }
    }
}
</script>
<style scoped lang="less">
    .title{
        font-size: 16px;
        padding: 10px 0;
    }
    .a-title{
        line-height: 20px;
        display: inline-block;
        padding: 5px 0;
        .index{
            display: inline-block;
            //padding-right: 5px;
        }
        //color: #;
    }
</style>
