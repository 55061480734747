<template>
    <div class="reply-item">
        <div class="heart-reply">
            <div v-if="reply.reply_id===$store.state.answer.best_reply_id" class="best-answer">
                <img src="/static/bbs/images/answer/best.png" alt="">
            </div>
            <div class="number">
                <div>{{ reply.like_sum || 0 }}</div>
                <div class="start">
                    <i class="el-icon-star-off"></i>
                </div>
            </div>
        </div>
        <div class="reply-body">
            <div v-if="reply.reply_id===$store.state.answer.best_reply_id" class="best-answer">
                <span class="best-text bbs-second-color">
                    置顶解答
                </span>
            </div>
            <div class="user-info">
                <div class="user-face">
                    <img :src="reply.from_username.face" alt="">
                </div>
                <div class="user-time">
                    <div class="username bbs-second-color">
                        {{ reply.from_username.nickname || reply.from_username.username || '未知用户' }}
                    </div>
                    <div class="time bbs-third-color">
                        <span v-if="reply.update_time&&reply.update_time>reply.create_time">
                            编辑于<format-time readable="1" :time="reply.update_time"></format-time>
                        </span>
                        <span v-else>
                            发布于<format-time readable="1" :time="reply.create_time"></format-time>
                        </span>
                    </div>
                </div>
            </div>
            <div :id="'reply_id_'+reply.reply_id" class="reply-content">
                <div ref="content" v-html="reply.content"></div>
            </div>
            <div class="verify-status" v-if="reply.review_status!=200">
                <span v-if="reply.review_status===1">审核中</span>
                <span v-if="reply.review_status===0">不通过</span>
            </div>
            <div v-if="$store.state.userData&&$store.state.userData.group_id==1&&verify.verify&&reply.review_status!=200" class="verify">
                <span class="title">审核:</span>
                <el-radio-group v-model="verify.review_status">
                    <el-radio :label="200">通过</el-radio>
                    <el-radio :label="0">不通过</el-radio>
                </el-radio-group>
                <span class="verify-btn">
                    <el-button size="medium"  type="primary" @click="verifyItem">确认</el-button>
                </span>
            </div>
            <div v-if="reply.review_status===200" class="reply-footer">
                <div class="add-comment">
                    <el-button type="primary" @click="addReply" plain size="small" icon="el-icon-edit">评论</el-button>
                    <el-button v-access="{url:'/bbs/reply/top'}" type="primary" @click="confirmReply" plain size="small" icon="el-icon-edit">置顶
                    </el-button>
                    <el-button v-self="{username:reply.from_username&&reply.from_username.username}" plain size="small" @click="$emit('editReply')" icon="el-icon-edit">编辑</el-button>
                </div>
                <div class="right-area bbs-second-color">
                    <span class="jubao">举报</span>
                    <span class="toggle-reply"
                          @click="control.replyShow=!control.replyShow">{{
                            control.replyShow ? '收起' : '展开'
                        }}评论({{ comment.total || 0 }}条)</span>
                </div>
            </div>
            <div v-show="control.replyShow&&(comment&&comment.total>0||control.show)" class="comment-container">
                <div v-show="control.show" class="comment-textarea">
                    <el-input
                        resize="none"
                        type="textarea"
                        :rows="3"
                        :placeholder="control.placeholder"
                        v-model="control.comment">
                    </el-input>
                    <div class="btn-comment-list">
                        <el-button size="mini" @click="control.show=false" plain>取消</el-button>
                        <el-button size="mini" @click="addComment" plain type="primary">提交</el-button>
                    </div>
                </div>
                <div class="comment-list">
                    <reply-comment :index="index" @addComment="addUserComment" :key="item.comment_id"
                                   v-for="(item,index) in comment.data" :comment="item"></reply-comment>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormatTime from "@/components/common/format-time";
import ReplyComment from "@/components/wenda/left/reply-comment";
import highlightElement from 'highlight.js';
import CopyButtonPlugin from 'highlightjs-copy';


export default {
    name: "reply-item",
    components: {
        ReplyComment, FormatTime,
    },
    props: {
        reply: {
            type: Object,
        },
        index: {}
    },
    data() {
        return {
            baseUrl: '/bbs/comment/index',
            baseConfirmUrl:"/bbs/reply/top",
            baseVerifyUrl:"/bbs/reply/verify",
            comment: {
                total:0,
                data:[],
            },
            control: {
                replyShow: true,
                placeholder: '留下你的精彩评论',
                show: false,
                comment: '',
            },
            verify:{
                verify:!!this.$route.query.verify,
                review_status:this.reply.review_status,
            }
        }
    },
    watch:{
        'reply.content':{
            handler:function (){
                this.$nextTick(()=>{
                    highlightElement.addPlugin(new CopyButtonPlugin({
                        lang: "zh"
                    }));
                    const blocks = this.$refs.content.querySelectorAll('pre code')
                    blocks.forEach((block) => {
                        highlightElement.highlightElement(block)
                    });
                });
            },
            immediate:true,
        }
    },
    mounted() {
        this.comment = JSON.parse(JSON.stringify(this.reply.comment_list || {data:[],total:0}));
    },
    methods: {
        addReply() {
            this.control.show = true;
        },
        addUserComment(data) {
            this.comment.data.splice(data.index + 1, 0, data.data);
        },
        addComment() {
            if (typeof this.control.comment === 'undefined' || this.control.comment === '') return;
            if (!this.$tools.checkLogin()){
                return;
            }
            this.$bbs.post(this.baseUrl, {
                content: this.control.comment,
                reply_id: this.reply.reply_id,
                to_username: this.control.to_username,
            }).then(data => {
                this.comment.data.unshift(data.data);
                this.comment.total++;
                this.control.comment = '';
                this.control.show = false;
            });
        },
        verifyItem(){
            if (this.verify.review_status=='1') return;
            this.$bbs.put(this.baseVerifyUrl,{
                reply_id:this.reply.reply_id,
                review_status:this.verify.review_status,
            },{need:{error:true}}).then((data)=>{
                this.$message.success('审核成功!');
                this.$store.commit('handleNotice',{type:'reply',id:this.reply.reply_id});
                this.reply.review_status=data.data.review_status;
            });
        },
        confirmReply() {
            this.$confirm('确认置顶此回答吗?', '操作确认', {
                type: 'success'
            }).then(() => {
                this.$bbs.post(this.baseConfirmUrl, {
                    reply_id: this.reply.reply_id,
                }).then(() => {
                    //操作成功，将些设置为最优解
                    this.$emit('confirmReply');
                    this.$message.success('操作成功!');
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.reply-item {
    display: flex;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.4);
    .best-answer{
        padding: 0 0 20px 0;
        height: 50px;
        .best-text{
            display: flex;
            height: 100%;
            align-items: center;
            font-weight: normal;
            font-size: 16px;

        }
    }
    .heart-reply {

        text-align: center;
        color: #0082FC;

        .number {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            width: 50px;
            border-radius: 4px;
            background-color: #E4ECFD;
            font-size: 14px;

            > div {
                height: 40%;
            }

            .start {
                margin-top: 6px;
                cursor: pointer;
            }

            i {
                font-size: 20px;
            }
        }
    }

    .reply-body {
        flex: 1;
        padding-left: 15px;

        .user-info {
            display: flex;
            height: 40px;
            align-items: center;

            .user-face {
                width: 40px;
                padding: 2px;
                border-radius: 100%;
                border: 2px solid #cccccc;
                height: 100%;

                img {
                    border-radius: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    display: inline-block;
                }
            }

            .user-time {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-evenly;
                padding-left: 20px;

                .time {
                    font-size: 12px;
                }
            }
        }

        .reply-content {
            padding: 15px 0;
            color: rgba(10, 18, 32, 1);
            p{
                line-height: 25px;
            }
        }
        .verify-status{
            color: #e6a23c;
            padding: 0 10px 10px 0;
        }
        .verify{
            color: #333;
            margin-bottom: 10px;
            padding: 25px 10px;
            background: #f0f9eb;
            .title{
                padding-right: 15px;
            }
            .verify-btn{
                display: inline-block;
                padding-left: 40px;
            }

        }
        .reply-footer {
            display: flex;
            align-items: center;

            .add-comment {
                flex: 1;
            }

            .right-area {
                width: 260px;
                text-align: right;

                .toggle-reply {
                    cursor: pointer;
                }

                > span {
                    display: inline-block;
                    padding: 0 10px;
                }
            }
        }

        .comment-container {
            padding: 10px;
            margin: 10px 0;
            background-color: #F7F9FD;

            .btn-comment-list {
                margin-top: 10px;
                text-align: right;
            }
        }

    }

}
</style>
